import React, { useEffect, useState } from "react";
import "./pagestyles.css";

function Stats() {
  const [currentSupply, setCurrentSupply] = useState("Loading...");
  const [currentHolders, setCurrentHolders] = useState("Loading...");

  useEffect(() => {
    const apiKey = "HHPUGSWZNZ7GKYFP9YVHJJSH1GUY48Q2P1";
    const contractAddress = "0x231A6BD8eB88Cfa42776B7Ac575CeCAf82bf1E21";

    const fetchCurrentSupply = async () => {
      const url = `https://api.etherscan.io/api?module=stats&action=tokensupply&contractaddress=${contractAddress}&apikey=${apiKey}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        console.log("Supply Data:", data); // Debugging: Check API response for supply
        if (data.status === "1" && data.result) {
          setCurrentSupply(Number(data.result)); // Ensure it's converted to a number
        } else {
          console.warn(
            "Supply fetch issue:",
            data.message || "Unexpected structure"
          );
          setCurrentSupply("Error fetching current supply");
        }
      } catch (error) {
        console.error("Error fetching current supply:", error);
        setCurrentSupply("Error");
      }
    };

    const fetchCurrentHolders = async () => {
      const url = `https://api.etherscan.io/api?module=token&action=tokenholdercount&contractaddress=${contractAddress}&apikey=${apiKey}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        console.log("Holders Data:", data); // Debugging: Check API response for holders

        if (data.status === "1" && data.result) {
          // Ensure result is converted to a number if it comes as a string
          setCurrentHolders(Number(data.result));
        } else {
          console.warn(
            "Holders fetch issue:",
            data.message || "Unexpected structure"
          );
          setCurrentHolders("Unable to fetch holders");
        }
      } catch (error) {
        console.error("Error fetching holders:", error);
        setCurrentHolders("Error");
      }
    };

    fetchCurrentSupply();
    fetchCurrentHolders();
  }, []);

  return (
    <div className="container-div">
      <div className="stats-grid">
        <div className="data-card">
          <h3>Amount of $PLUG Supply BURNT:</h3>
          <p>
            %{" "}
            {typeof currentSupply === "number"
              ? (
                  -1 *
                  (1 - (3300000000 * 10 ** 18) / currentSupply) *
                  100
                ).toFixed(2)
              : currentSupply}
          </p>
        </div>

        <div className="data-card">
          <h3>Honeycombs Swept:</h3>
          <p>242</p>
        </div>
      </div>
    </div>
  );
}

export default Stats;
