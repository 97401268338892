import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import "./NavigationBar.css";

const Navigationbar = () => {
  const [isConnected, setIsConnected] = useState(false);

  return (
    <header className="header">
      <NavLink to="/" className="nav__logo" activeClassName="active">
        Beraplug
      </NavLink>

      <nav className="nav__menu">
        <ul
          className="nav__list"
          style={{ marginRight: isConnected ? "2rem" : "1rem" }}
        >
          <li className="nav__item">
            <NavLink to="/claim" className="nav__link" activeClassName="active">
              Claim
            </NavLink>
          </li>
          <li className="nav__item">
            <a
              href="https://app.uniswap.org/swap?outputCurrency=0x231A6BD8eB88Cfa42776B7Ac575CeCAf82bf1E21&chain=ethereum"
              className="nav__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Swap
            </a>
          </li>
          <li className="nav__item">
            <NavLink to="/stats" className="nav__link" activeClassName="active">
              Stats
            </NavLink>
          </li>
          <li className="nav__item">
            <NavLink
              to="/bridge"
              className="nav__link"
              activeClassName="active"
            >
              Bridge
            </NavLink>
          </li>
        </ul>
      </nav>

      <div className="nav__right">
        <div className="connect-button-classname">
          <ConnectButton
            onConnect={() => setIsConnected(true)}
            onDisconnect={() => setIsConnected(false)}
          />
        </div>
      </div>
    </header>
  );
};

export default Navigationbar;
