import React from "react";
import "./pagestyles.css";

function Claim() {
  return (
    <div>
      {/* <p className="p1">Are you worthy of being plugged?</p>
      <p className="p2">Connect wallet to find out</p> */}
      <p className="p1">Airdrop thoon?</p>
    </div>
  );
}

export default Claim;
