import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import myLottieFile from "./images/pluglottie.json"; // Path to your Lottie file
import "./pagestyles.css";

const Home = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%";
    const fontSize = 16;
    const columns = canvas.width / fontSize;

    // Initialize drops array with random Y positions
    const drops = Array.from(
      { length: Math.floor(columns) },
      (_, i) => -i * Math.random() * 20
    );

    function draw() {
      // Set the background fill color
      ctx.fillStyle = "rgba(0, 0, 0, 0.075)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Set the text color
      ctx.fillStyle = "hsl(46, 100%, 50%)"; // New yellow color for the text
      ctx.font = fontSize + "px monospace";

      for (let i = 0; i < drops.length; i++) {
        const text = characters.charAt(
          Math.floor(Math.random() * characters.length)
        );

        // Use 'i * fontSize' for the x position
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        // Reset the drop's y position if it goes off the bottom
        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }
        drops[i] += 0.2;
      }
      requestAnimationFrame(draw);
    }

    draw();
  }, []);

  return (
    <div>
      <canvas
        ref={canvasRef}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <div className="container-div">
        <Lottie animationData={myLottieFile} />
      </div>
    </div>
  );
};

export default Home;
