import React from "react";

function Swap() {
  return (
    <div>
      <h1>Swap Page</h1>
      <p>This will link to Uniswap.</p>
    </div>
  );
}

export default Swap;
